import React, { useState, useEffect } from 'react';

const generatePassword = (length, useUpperCase, useLowerCase, useNumbers, useSpecialChars) => {
  let charset = '';
  if (useUpperCase) charset += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (useLowerCase) charset += 'abcdefghijklmnopqrstuvwxyz';
  if (useNumbers) charset += '0123456789';
  if (useSpecialChars) charset += '!@#$%^&*()_+{}[]|;:,.<>?';

  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

const PasswordGenerator = () => {
  const [password, setPassword] = useState('');
  const [length, setLength] = useState(12);
  const [useUpperCase, setUseUpperCase] = useState(true);
  const [useLowerCase, setUseLowerCase] = useState(true);
  const [useNumbers, setUseNumbers] = useState(true);
  const [useSpecialChars, setUseSpecialChars] = useState(true);

  useEffect(() => {
    handleGeneratePassword();
  }, []);

  const handleGeneratePassword = () => {
    const newPassword = generatePassword(length, useUpperCase, useLowerCase, useNumbers, useSpecialChars);
    setPassword(newPassword);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(password);
    alert('Password copied to clipboard!');
  };

  return (
    <div>
      <h2>Password Generator</h2>

      <button className="generate-button" style={{ backgroundColor: '#007bff', color: '#fff', padding: '10px', borderRadius: '5px', border: 'none', marginRight: '10px' }} onClick={handleGeneratePassword}>Generate Password</button>
      {password && (
        <div className="generated-password" style={{ backgroundColor: '#f4f4f4', padding: '10px', marginTop: '10px' }}>
          <p style={{ fontFamily: 'monospace', fontSize: '1.2rem', padding: '5px', backgroundColor: '#fff', borderRadius: '5px' }}>{password}</p>
          <button className="copy-button" style={{ backgroundColor: '#28a745', color: '#fff', padding: '10px', borderRadius: '5px', border: 'none' }} onClick={handleCopyToClipboard}>Copy to Clipboard</button>
        </div>
      )}
      
      <div className="settings">

        <label>Password Length: {length}</label>
        <input
          type="range"
          value={length}
          onChange={(e) => setLength(parseInt(e.target.value))}
          min={4}
          max={32}
          step={1}
        />
      </div>
      <div className="settings">
        <label>Include Uppercase Letters:</label>
        <input
          type="checkbox"
          checked={useUpperCase}
          onChange={() => setUseUpperCase(!useUpperCase)}
        />
      </div>
      <div className="settings">
        <label>Include Lowercase Letters:</label>
        <input
          type="checkbox"
          checked={useLowerCase}
          onChange={() => setUseLowerCase(!useLowerCase)}
        />
      </div>
      <div className="settings">
        <label>Include Numbers:</label>
        <input
          type="checkbox"
          checked={useNumbers}
          onChange={() => setUseNumbers(!useNumbers)}
        />
      </div>
      <div className="settings">
        <label>Include Special Characters:</label>
        <input
          type="checkbox"
          checked={useSpecialChars}
          onChange={() => setUseSpecialChars(!useSpecialChars)}
        />
      </div>
      
    </div>
  );
};

export default PasswordGenerator;
